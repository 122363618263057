export default {
    bindings: {
        itens: '<'
    },

    template: `
        <h2>Favoritos</h2>

        <table class="table table-hover table-sm">
            <caption>Lista de itens favoritos</caption>

            <thead class="thead-light">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Grupo</th>
                    <th scope="col">Ambiente</th>
                    <th scope="col">Sistema</th>
                    <th scope="col"></th>
                </tr>
            </thead>

            <tbody>
                <tr ng-repeat="item in $ctrl.itens track by $index">
                    <th scope="row">{{ $index + 1 }}</th>
                    <td>{{ item.grupo }}</td>
                    <td>{{ item.ambiente }}</td>
                    <td>{{ item.sistema }}</td>
                    <td>
                        <a ng-href="{{ item.url }}" target="_blank">
                            <i class="fa fa-arrow-circle-right list-group-item-btn"></i>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    `
}