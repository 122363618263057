import dados from './dados.json';
import sistemas from './sistemas.json';

export default class HomeController {
    constructor() {
        // variáveis
        this.grupos = [];
        this.favoritos = [];

        this.grupoSelecionado = undefined;
        this.ambienteSelecionado = undefined;

        this.carregarDados();
    }

    // CARREGA GRUPOS E AMBIENTES
    carregarDados() {
        this.grupos = dados;
        this.grupoSelecionado = this.grupos[0];

        // carregar favoritos
        var favoritosLocal = JSON.parse(window.localStorage.getItem('favoritos'));
        this.favoritos = favoritosLocal || [];

        setTimeout(this.exibirConteudo, 1000);
    }

    exibirConteudo() {
        var loader = document.getElementById("loader");
        var content = document.getElementById("content");

        angular.element(loader).css("display", "none");
        angular.element(content).css("display", "block");
    }

    selecionarGrupo(grupo) {
        if (!grupo) return;

        this.grupoSelecionado = grupo;
        this.ambienteSelecionado = undefined;
    }

    selecionarAmbiente(ambiente) {
        if (!ambiente) return;

        ambiente.sistemas = sistemas;

        this.ambienteSelecionado = ambiente;
    }

    irParaAmbiente(ambiente) {
        if (!ambiente) return;

        var url = ambiente.url;

        if (this.grupoSelecionado.padrao) {
            url = ambiente.url + '/portal';
        }

        window.open(url, "_blank");
    }

    guardarItemFavorito(sistema, url) {
        // guarda item nos favoritos
        var favorito = {
            grupo: this.grupoSelecionado.nome,
            ambiente: this.ambienteSelecionado.nome,
            sistema: sistema.nome,
            url: url
        };

        // remove 1o item da lista
        if (this.favoritos.length >= 5) {
            this.favoritos.pop();
        }

        this.favoritos.unshift(favorito);

        window.localStorage.setItem('favoritos', JSON.stringify(this.favoritos));
    }

    irParaSistema(sistema) {
        if (!sistema) return;

        var url = sistema.url;

        if (this.grupoSelecionado.padrao) {
            url = this.ambienteSelecionado.url + sistema.url;
        }

        this.guardarItemFavorito(sistema, url);

        window.open(url, "_blank");
    }
}