import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import './style.css';

import angular from 'angular';
import FavoritosComponent from './componentes/favoritos.component'

import HomeController from './app.homeController';
import PerfectScrollbar from 'perfect-scrollbar';

const MODULE_NAME = 'app';

angular.module('app', [])
       .component("favoritos", FavoritosComponent)
       .controller('homeCtrl', HomeController);

const listaGrupos = document.querySelector('#listaGrupos');
const listaAmbientes = document.querySelector('#listaAmbientes');
const listaSistemas = document.querySelector('#listaSistemas');

const ps1 = new PerfectScrollbar(listaGrupos);
const ps2 = new PerfectScrollbar(listaAmbientes);
const ps3 = new PerfectScrollbar(listaSistemas);

export default MODULE_NAME;       